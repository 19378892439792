<template>
    <div>
        <van-cell-group title="通用">
            <van-cell title="新增" is-link size="large" icon="plus" to="/safe/cre" />
            <van-cell title="待分配" is-link size="large" icon="exchange"
                :to="{ path: '/safe/abInfo', query: { sta: 1 } }" v-if="mender_role=='ADMIN'" />
            <van-cell title="待处理" is-link size="large" icon="brush-o"
                :to="{ path: '/safe/abInfo', query: { sta: 5 } }" />
            <van-cell title="待验收" is-link size="large" icon="success"
                :to="{ path: '/safe/abInfo', query: { sta: 7 } }" v-if="mender_role=='ADMIN'" />
            <van-cell title="我的记录" is-link size="large" icon="user-circle-o" to="/safe/record?for=MINE"  />
            <van-cell title="全部记录" is-link size="large" icon="user-circle-o" to="/safe/record" v-if="mender_role=='ADMIN'" />
        </van-cell-group>
    </div>
</template>
<script>
export default {
  data() {
    return {
      role: {
        mat: false,
        mat_valid: false,
        cap: false,
        safe: false,
      },
      mender_role: "",
    };
  },
  created() {
    this.getMenders();
  },
  methods: {
    getMenders() {
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/Mobile/FIX/MenderApi/GetList",
        completed: function (its, n) {
          console.log(its);
          let user = self.whale.user.get();
          for (let i = 0; i < n; i++) {
            let o = its.ITEMS[i];
            if (o.TEA_ID == user.ID) {
              self.mender_role = o.ROLE;
              break;
            }
          }
        },
      });
    },
  },
};
</script>
<style lang="less" scoped></style>